const { default: Thoth } = require('../base/thoth');

class AssignorService extends Thoth {
    async getByDate() {
        return this.fetch({
            url: '/assignor-monitoring',
        });
    }

    async getGroup(groupId) {
        return this.fetch({
            url: `/assignor-monitoring/${groupId}`,
        });
    }
}

const service = new AssignorService();
export default service;
