<template>
  <div :key="componentKey">
    <b-form-input
      v-model="filter"
      class="mb-1"
      placeholder="Razão Social"
    />
    <Table
      :data="assignorsSorted"
      :current-page="currentPage"
      :clickable="clickable"
      @click-row="(data) => $emit('click-row', data)"
    />
    <b-pagination
      v-model="currentPage"
      :total-rows="assignorsSorted.length"
      :per-page="10"
      align="center"
      size="sm"
      class="my-1"
    />
  </div>
</template>

<script>
import {
    BFormInput,
    BPagination,
} from 'bootstrap-vue';

import Table from './UniqueTable.vue';

export default {
    components: {
        Table,
        BPagination,
        BFormInput,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },

        clickable: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            filter: '',
            currentPage: 1,
            componentKey: 0,
        };
    },

    computed: {
        assignorsSorted() {
            const assignors = JSON.parse(JSON.stringify(this.data));
            const filterInLowerCase = this.filter.toLowerCase();
            const response = assignors.filter(item => item.name.toLowerCase().includes(filterInLowerCase)).sort((a, b) => a.name.localeCompare(b.name));
            return response;
        },
    },
};

</script>
