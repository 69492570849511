<template>
  <b-table
    class="text-center"
    :current-page="currentPage"
    :per-page="10"
    responsive="xl"
    :hover="clickable"
    :fields="[
      { key:'name', label: 'Nome'},
      { key:'credit_limit', label: 'Limite de Crédito'},
      { key:'open', label: 'Risco Em Aberto'},
      { key:'on_time', label: 'Liquidez'},
      { key:'rebuy', label: 'Recompra'},
      { key:'extended', label: 'Prorrogação'},
      { key:'overdue', label: 'Atraso'},
      { key:'expired', label: 'Vencido'},
      { key:'variation', label: 'Variação Volume'},
    ]"
    :items="data"
    @row-clicked="(data) => $emit('click-row', data)"
  >
    <template #cell(credit_limit)="assignor">
      {{ assignor.item.credit_limit | value }}
    </template>

    <template #cell(on_time)="assignor">
      <span
        v-b-tooltip.hover.top="'D-91'"
      >
        {{ percentageOperation(+assignor.item.total_payed || 0, +assignor.item.on_time || 0) || 0 }}
      </span>

    </template>

    <template #cell(rebuy)="assignor">
      <span
        v-b-tooltip.hover.top="'D-91'"
      >
        {{ percentageOperation(+assignor.item.total_payed || 0, +assignor.item.rebuy || 0) || 0 }}
      </span>
    </template>

    <template #cell(overdue)="assignor">
      <span
        v-b-tooltip.hover.top="'D-91'"
      >
        {{ percentageOperation(+assignor.item.total_payed || 0, +assignor.item.overdue || 0) || 0 }}
      </span>

    </template>

    <template #cell(total_payed)="assignor">
      <span
        v-b-tooltip.hover.top="'D-91'"
      >
        {{ +assignor.item.total_payed | value }}
      </span>
    </template>

    <template #cell(expired)="assignor">
      <span
        v-b-tooltip.hover.top="'Total da Vida'"
      >
        {{ assignor.item.expired | value }}
      </span>

    </template>

    <template #cell(open)="assignor">
      {{ assignor.item.open | value }}
    </template>

    <template #cell(extended)="assignor">
      {{ (percentageOperation(+assignor.item.total || 0, +assignor.item.extended || 0) ||0) }}
    </template>

    <template #cell(variation)="data">
      <span
        v-b-tooltip.hover.top="lastTwoMonths"
      >
        {{ (((data.item.total_last_month / data.item.total_two_months_ago - 1) * 100) || 0).toFixed(2) }}%
      </span>
    </template>

  </b-table>
</template>

<script>
import {
    BTable,
    VBTooltip,
} from 'bootstrap-vue';

import percentOperation from '@/utils/operations/percentage';

export default {
    components: {
        BTable,
    },

    directives: {
        'b-tooltip': VBTooltip,
    },

    props: {
        currentPage: {
            type: Number,
            default: 1,
        },

        data: {
            type: Array,
            required: true,
        },

        clickable: {
            type: Boolean,
            default: () => false,
        },
    },

    computed: {
        lastTwoMonths() {
            const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
                'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const lastMonth = months[(currentMonth - 1 + 12) % 12];
            const secondLastMonth = months[(currentMonth - 2 + 12) % 12];

            return `${secondLastMonth}-${lastMonth}`;
        },

    },

    methods: {
        percentageOperation(a, b) {
            const value = (percentOperation(a, b));
            return `${!Number.isNaN(value) ? (value).toFixed(2) : (0).toFixed(2)}%`;
        },
    },
};

</script>
